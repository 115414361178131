export default {
  environment: 'production',
  apiBaseUrl: 'https://vault.zencity.io',
  organicApiBaseUrl: 'https://api.zencity.io',
  insights: {
    domain: 'https://app.zencity.io',
  },
  surveyManager: {
    domain: `https://app.zencity.io/admin/surveys/survey-manager`,
    newSurvey: '/survey/new',
  },
  communityAsks: {
    baseRouterUrl: '/admin/surveys/community-asks',
  },
  communitySurveys: {
    baseRouterUrl: '/admin/surveys/community-survey',
  },
  customerExperience: {
    baseRouterUrl: '/admin/surveys/customer-experience',
  },
  blockwise: {
    baseRouterUrl: '/admin/surveys/blockwise',
  },
  pulse: {
    baseRouterUrl: '/admin/surveys/pulse',
  },
};
