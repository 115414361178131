/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
/* eslint-disable react/jsx-no-bind */
import { Checkbox, CheckboxState, VerticalListWithSeparators } from '@zencity/common-ui';
import { DateRangeContext } from 'contexts/DateRangeContext';
import { MapsContext } from 'contexts/MapsContext';
import useScoreDateRangeFilter from 'hooks/score/dateRangeFilter';
import React, { MouseEventHandler, ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AreaWithScore } from 'types/areas';
import { getScorePerDateRange } from 'utils/dateRange';
import styles from './SatisfactionScoresByAreaList.module.scss';
import { SatisfactionScoresByAreaRow } from './SatisfactionScoresByAreaRow/SatisfactionScoresByAreaRow';
import { useFilteredAreaNames } from './useFilteredAreaNames';

/**
 * The scores by area list, to be presented on the Maps Tab.
 */
export const SatisfactionScoresByAreaList: React.FC = function SatisfactionScoresByAreaList(): ReactElement {
  const [selectAllCheckbox, setSelectAllCheckbox] = useState<CheckboxState>(CheckboxState.INDETERMINATE);
  const { areasWithScoresData, selectedAreaIds, setSelectedAreaIds, setHoveredAreaId, baseDivisionId, divisions } =
    useContext(MapsContext);
  const { comparisonDateRange } = useContext(DateRangeContext);
  const { dateRangeFilter } = useScoreDateRangeFilter();
  const { t: translate } = useTranslation();
  const { filteredAreaIds } = useFilteredAreaNames();

  const handleOnCheckboxClick = () => {
    const baseDivision = divisions?.find((division) => division.id === baseDivisionId);
    const baseDivisionChildrenAreaIds =
      divisions
        ?.filter((division) => division.parent_division === baseDivisionId)
        .map((division) => division.geo_targeting_ref.id) || [];
    const allAreaIds = [...baseDivisionChildrenAreaIds];

    if (baseDivision) {
      // Add parent division to the list.
      allAreaIds.unshift(baseDivision.geo_targeting_ref.id);

      const levelZeroDivision = divisions?.find((division) => division.depth === 0);
      if (baseDivision.id !== levelZeroDivision?.id && levelZeroDivision) {
        // Add level zero division to the list.
        allAreaIds.unshift(levelZeroDivision?.geo_targeting_ref.id);
      }
    }

    if (selectAllCheckbox === CheckboxState.CHECKED) {
      setSelectedAreaIds([]);
    } else {
      setSelectedAreaIds(filteredAreaIds);
    }
  };

  useEffect(() => {
    if (filteredAreaIds.every((areaId) => selectedAreaIds.includes(areaId))) {
      setSelectAllCheckbox(CheckboxState.CHECKED);
    } else if (selectedAreaIds.length === 0) {
      setSelectAllCheckbox(CheckboxState.UNCHECKED);
    } else {
      setSelectAllCheckbox(CheckboxState.INDETERMINATE);
    }
  }, [selectedAreaIds, filteredAreaIds]);

  if (!dateRangeFilter) {
    return <></>;
  }

  return (
    <div className={styles.satisfactionScoresByAreaList}>
      <div className={styles.listHeaderText}>
        <span className={styles.checkboxContainer} onChange={handleOnCheckboxClick}>
          <Checkbox state={selectAllCheckbox} setState={setSelectAllCheckbox} name="indeterminateCheckbox" />
          <p>{translate('map.satisfactionList.areasLabel')}</p>
        </span>
        <p>{translate('map.satisfactionList.satisfactionLabel')}</p>
      </div>
      <VerticalListWithSeparators removeFirstChildTopSeparator={false}>
        {filteredAreaIds.map((areaId) => {
          const areaWithScore = areasWithScoresData[areaId] as AreaWithScore;
          const selectedFilterScores = getScorePerDateRange({
            scorePerDateRange: areaWithScore.scoresPerPeriod,
            dateRangeFilter,
          });

          // Adding comparison score to display in the breakdown.
          const comparisonScore = getScorePerDateRange({
            scorePerDateRange: areaWithScore.scoresPerPeriod,
            dateRangeFilter: {
              endDate: comparisonDateRange?.endDate ?? '',
              startDate: comparisonDateRange?.startDate ?? '',
            },
          });

          const mouseEnterHandler: MouseEventHandler<HTMLLIElement> = function mouseEnterHandler(_event) {
            setHoveredAreaId(areaWithScore.id);
          };
          const mouseLeaveHandler: MouseEventHandler<HTMLLIElement> = function mouseLeaveHandler(_event) {
            setHoveredAreaId('');
          };

          return (
            <SatisfactionScoresByAreaRow
              key={areaId}
              areaId={areaId}
              areaName={areaWithScore.name}
              satisfactionScore={selectedFilterScores?.scores}
              totalSubmissions={selectedFilterScores?.totalSubmissions}
              color={areaWithScore.color}
              onMouseEnter={mouseEnterHandler}
              onMouseLeave={mouseLeaveHandler}
              defaultCheckboxState={selectedAreaIds.includes(areaId) ? CheckboxState.CHECKED : CheckboxState.UNCHECKED}
              comparisonScore={comparisonScore?.scores}
              totalSubmissionsToCompare={comparisonScore?.totalSubmissions}
            />
          );
        })}
      </VerticalListWithSeparators>
    </div>
  );
};
